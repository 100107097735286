import React from "react";
import Loadable from "@loadable/component";
import GlobalStyles from "../globalStyles";
// import IntroContent from "../../content/IntroContent.json";
// import MiddleBlockContent from "../../content/MiddleBlockContent.json";
// import AboutContent from "../../content/AboutContent.json";
// import MissionContent from "../../content/MissionContent.json";
// import ProductContent from "../../content/ProductContent.json";
// ------------------------------------------------------------------
import FeatureContentZero from "../content/FeatureContentZero.json";
import FeatureContentOne from "../content/FeatureContentOne.json";
import FeatureContentTwo from "../content/FeatureContentTwo.json";
import FeatureContentThree from "../content/FeatureContentThree.json";
// import MiddleBlockHeading from "../content/MiddleBlockContent.json";
// ------------------------------------------------------------------
// import KeyFeatures from "./KeyFeatures";

// FeatureContentZero

// import ContactContent from "../../content/ContactContent.json";
// import Features from ".";
// import buttons from "./buttons";

import Footer from "../components/Footer";
import Header from "../components/Header";
// import SecHeader from "../components/SecHeader";
// import SecHeader from "../components/SecHeader";
// import Header from "../components/Header";
// const ContactFrom = Loadable(() => import("../ContactForm"));
const ContentBlock = Loadable(() => import("../components/ContentBlock"));
// const MiddleBlock = Loadable(() => import("../components/MiddleBlock"));
const Container = Loadable(() => import("../common/Container"));
const ScrollToTop = Loadable(() => import("../common/ScrollToTop"));
// const TestingContent = Loadable(() => import("../../common/TestingContent"));

const Features = () => {
  return (
    <>
      <GlobalStyles />
      <Header />
      <Container>
        <ScrollToTop />
        {/* <MiddleBlock title={MiddleBlockHeading.title} /> */}
        <ContentBlock
          type="new"
          title={FeatureContentZero.title}
          content={FeatureContentZero.text}
          icon="features1.svg"
          id="intro"
        />
        <ContentBlock
          type="left"
          title={FeatureContentOne.title}
          content={FeatureContentOne.text}
          icon="features2.svg"
          id="product"
        />
        <ContentBlock
          type="new"
          title={FeatureContentTwo.title}
          content={FeatureContentTwo.text}
          icon="features3.svg"
          id="product"
        />
        <ContentBlock
          type="left"
          title={FeatureContentThree.title}
          content={FeatureContentThree.text}
          icon="features4.svg"
          id="product"
        />
        {/* <ContactFrom
        title={ContactContent.title}
        content={ContactContent.text}
        id="contact"
      /> */}
        <Footer />
      </Container>
    </>
  );
};

export default Features;
